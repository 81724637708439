import './assets/sass/main.scss';
import '@swapin/ui/dist/style.css';
import '@swapin/web3/dist/style.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { VueQueryPlugin } from '@tanstack/vue-query';
import * as Sentry from '@sentry/vue';
import i18n from '@swapin-hub/i18n';
import { createModal } from '@swapin/ui/Modal';
import { Web3Plugin } from '@swapin/web3';

import App from './App.vue';
import router from './router';
import routerScrollRestore from './router/scrollRestore';
import apiInterceptors from './api/interceptors';
import { queryClient } from './queries';

const TWA = import.meta.env.VITE_PLATFORM_TWA
  // eslint-disable-next-line unicorn/no-await-expression-member
  ? (await import('@twa-dev/sdk')).default
  : undefined;

const app = createApp(App);

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://4f96dbd6b583c0625cd5601bab768cc3@o1262827.ingest.sentry.io/4505996576292864',
    release: __APP_VERSION__,
    environment: import.meta.env.VITE_ENV_DEV ? 'development' : 'production',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [/https:\/\/stan.piix.eu\/api/],
      }),
    ],
    tracesSampleRate: 1,
    tracePropagationTargets: [
      'widget.piix.eu',
      'widget.swapin.com',
    ],
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
  });
}

app.use(VueQueryPlugin, { queryClient });
app.use(createPinia());
app.use(router);
app.use(i18n);
app.use(createModal());
app.use(Web3Plugin);

routerScrollRestore(router);
apiInterceptors(router);

TWA?.ready();
TWA?.expand();
TWA?.enableClosingConfirmation();

app.mount('#app');
